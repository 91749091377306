export default (function () {
  return {
    cantidad: {
      display: 'flex',
      width: 100,
      height: 40,
      borderColor: '#a9a9a9',
      paddingLeft: 5
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      flexWrap: 'wrap',
      alignSelf: 'center',
      paddingLeft: 15,
      paddingRight: 15,
      width: '100%',
      maxWidth: 1200,
      // Add 30px to 1200px limit to include padding spacing
      minWidth: '80%',
      boxSizing: 'border-box'
    },
    galeriaImagenes: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignSelf: 'flex-start',
      justifyContent: 'space-between'
    },
    vistaProductoContenedor: {
      display: 'flex',
      flex: 1,
      alignItems: 'stretch',
      // minWidth: 270,
      paddingLeft: 30,
      paddingRight: 0,
      flexWrap: 'wrap',
      flexDirection: 'column'
    },
    TitleText: {
      display: 'flex',
      fontSize: 20
    },
    TitleTextS: {
      display: 'flex',
      fontSize: 20,
      color: '#873695'
    },
    TitleTextM: {
      display: 'flex',
      fontSize: 20
    },
    DividerText: {
      display: 'flex',
      backgroundColor: '#f8f8f8'
    },
    PuntosTextNumber: {
      display: 'flex',
      fontSize: 24,
      color: '#666',
      textAlign: 'center',
      fontWeight: 'bold',
      marginTop: 10
    },
    badgesContainer: {
      display: 'flex',
      width: '100%',
      position: 'absolute',
      zIndex: 2,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },
    badgeEditContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start'
    },
    BadgeText: {
      display: 'flex',
      fontSize: 18,
      letterSpacing: 2,
      fontWeight: '500'
    },
    opcionesProducto: {},
    selectores: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignSelf: 'stretch',
      flexWrap: 'wrap',
      marginTop: 0,
      marginBottom: 15,
      marginRight: 15,
      flexDirection: 'column',
      width: 'auto',
      height: 66
    },
    label: {
      display: 'flex',
      color: '#873695',
      height: 35,
      fontSize: 14,
      width: 70,
      fontWeight: '500',
      justifyContent: 'center',
      lineHeight: 35
    },
    select: {
      display: 'flex',
      backgroundColor: '#fff',
      color: '#873695',
      height: 35,
      fontSize: 13,
      minWidth: 60,
      fontWeight: '500',
      borderRadius: 5,
      marginLeft: 7,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderTopWidth: 1,
      borderRightWidth: 1,
      borderBottomColor: '#000',
      borderLeftColor: '#000',
      borderTopColor: '#000',
      borderRightColor: '#000'
    },
    divider: {
      display: 'flex',
      backgroundColor: '#ebebeb',
      height: 2,
      width: '100%',
      marginTop: 30,
      marginBottom: 30,
      flexWrap: 'wrap'
    },
    dividerResponsivo: {
      display: 'flex',
      backgroundColor: 'transparent',
      flexWrap: 'wrap'
    },
    dividerResponsivo1: {
      display: 'flex',
      height: 2,
      width: '100%',
      marginTop: 0,
      marginBottom: 0
    },
    dividerResponsivo2: {
      display: 'flex',
      height: 740,
      width: 3,
      marginLeft: 10,
      marginRight: 10
    },
    especificacionTextoContainer: {
      display: 'flex',
      padding: '5px 20px',
      flexDirection: 'row',
      flexWrap: 'wrap',
      flex: 1,
      alignSelf: 'stretch',
      alignItems: 'stretch'
    },
    especificacionTexto: {
      display: 'flex',
      flex: 1,
      fontSize: 14,
      color: 'rgba(0, 0, 0, .6)'
    },
    textoPuntos: {
      color: '#7E2B89',
      fontWeight: '600',
      margin: 0
    },
    textoPuntosLg: {
      fontSize: 24,
      fontWeight: '600'
    },
    textoTachado: {
      fontSize: 24,
      textDecoration: 'line-through #E72470 3px',
      webkitTextDecoration: 'line-through #E72470 3px',
      webkitTextDecorationLine: 'line-through !important',
      webkitTextDecorationColor: '#e72470 !important',
      webkitTextDecorationStyle: 'solid !important',
      webkitTextDecorationThickness: '3px !important',
      marginTop: 0
    },
    textoTachadoLg: {
      fontSize: 24,
      textDecoration: 'line-through #E72470 3px',
      webkitTextDecoration: 'line-through #E72470 3px',
      webkitTextDecorationLine: 'line-through !important',
      webkitTextDecorationColor: '#e72470 !important',
      webkitTextDecorationStyle: 'solid !important',
      webkitTextDecorationThickness: '3px !important',
      marginTop: 5
    },
    tituloProducto: {
      display: 'flex',
      fontSize: 24,
      marginBottom: 0,
      fontWeight: '300',
      color: '#7E2B89'
    },
    tituloProductoSm: {
      display: 'flex',
      fontWeight: '300',
      fontSize: 16
    },
    vistaContenedorGeneral: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      flex: 1,
      width: '100%'
    },
    textoVisorPuntos: {
      fontSize: 16,
      color: 'rgba(0, 0, 0, .6)'
    },
    textoVisorPuntosSm: {
      fontSize: 16,
      fontWeight: '500'
    },
    textoVisorPuntosLg: {
      fontSize: 24,
      fontWeight: '500',
      color: '#7E2B89'
    },
    textoVisorTachado: {
      color: 'black',
      textDecoration: 'line-through'
    },
    textoVisorTachadoLg: {
      fontSize: 15
    },
    imagenLogo: {
      display: 'flex',
      width: 40,
      height: 40,
      marginRight: 10
    },
    vistaContenedorProductoRelacionados: {
      display: 'flex',
      flex: 1,
      marginBottom: 30,
      width: '100%'
    },
    vistaContenedorProductoRelacionados1: {
      display: 'none'
    },
    definiciones: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
      flexWrap: 'wrap',
      marginTop: 0,
      width: '100%'
    },
    definiciones1: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch'
    },
    definiciones2: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    titulosDefiniciones: {
      display: 'flex',
      color: 'rgba(0, 0, 0, .6)',
      marginRight: 10,
      fontWeight: '400',
      padding: '12px 0px',
      fontSize: 14
    },
    titulosDefiniciones1: {
      display: 'flex',
      textAlign: 'left',
      padding: 0,
      width: '100%'
    },
    titulosDefiniciones2: {
      display: 'flex',
      textAlign: 'right',
      width: 130
    },
    condicionesContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    especificacionesContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      // minWidth: 270,
      // paddingLeft: 10,
      // paddingRight: 10,
      width: '100%'
    },
    especificacionesText: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      alignSelf: 'stretch',
      marginBottom: 30
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row'
    },
    pL0: {
      display: 'flex',
      paddingLeft: 0
    },
    pL30: {
      display: 'flex',
      paddingLeft: 30
    },
    widthFull: {
      display: 'flex',
      width: '100%'
    },
    width45: {
      display: 'flex',
      minWidth: 470
    },
    etiqueta: {
      display: 'flex',
      backgroundColor: '#E9E0ED',
      padding: '1px 15px',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 10,
      marginTop: 5
    },
    icono: {
      display: 'flex',
      width: 24,
      height: 24,
      marginRight: 10
    },
    etiquetaText: {
      display: 'flex',
      color: '#873695',
      fontWeight: '400',
      marginTop: 0,
      marginBottom: 0
    },
    relacionados: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      flexWrap: 'wrap',
      flex: 1,
      justifyContent: 'center',
      marginTop: 30
    },
    containerStyleRelacionado: {
      display: 'flex',
      backgroundColor: '#f2f2f2',
      flexDirection: 'row'
    },
    containerDireccion: {
      marginBottom: 50
    },
    canjeContainer: {
      flexDirection: 'row',
      alignItems: 'flex-end'
    },
    carrusel: {
      width: ' 100%'
    }
  };
});
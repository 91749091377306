import _toConsumableArray from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { CREAR_TEMPORAL, crearTemporalFail, crearTemporalSuccess, CREAR_TEMPORAL_FAIL, CREAR_TEMPORAL_SUCCESS, CREAR_NUEVA, crearNuevaFail, crearNuevaSuccess, CREAR_NUEVA_FAIL, CREAR_NUEVA_SUCCESS, setCorreoTemporal, setTelefonoTemporal } from './contrasena.actions';
import { hideNotifier, showNotifier, hideSecondaryProgressBar, showSecondaryProgress } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { pushRoute } from '@cencosud/puntos-core/src/features/router/history.actions';
import ApiServerError from '@cencosud/puntos-core/src/features/api/ApiServerError';
import processErrorMessage from '@cencosud/puntos-core/src/features/api/processErrorMessage';
import { setDetalle } from '../usuario/usuario.actions';
import { securityEndpoints } from '../api/endpoints';
import { eliminarCaracteresEspeciales } from '../common/rut';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import { createFailedFormEvent, createSuccessfulFormEvent } from '@cencosud/puntos-web/src/analytics/eventCreators';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
export var crearTemporalFlujo = function crearTemporalFlujo(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== CREAR_TEMPORAL) {
        return nextResponse;
      }
      var solicitud = action.payload;
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(securityEndpoints.usuario.restablecerContrasena(), crearTemporalSuccess, crearTemporalFail, {
        body: {
          run: solicitud.rut,
          type: solicitud.recoverMethod ? solicitud.recoverMethod.toUpperCase() : '',
          // Backend necesita un texto de al menos 1 carácter
          gRecaptchaResponse: solicitud.captcha || 'a'
        }
      }))]);
    };
  };
};
export var crearTemporalProcesar = function crearTemporalProcesar(_ref2) {
  var dispatch = _ref2.dispatch,
    getState = _ref2.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      var hideProgress = function hideProgress() {
        return dispatch(hideSecondaryProgressBar());
      };
      switch (type) {
        case CREAR_TEMPORAL_FAIL:
          {
            var error = payload;
            registerEvent(createFailedFormEvent({
              category: eventTypes.categories.contrasegna,
              name: 'Enviar Contraseña Temporal: fallo',
              error: error
            }));
            var getServerErrorDispatches = function getServerErrorDispatches() {
              return [dispatch(showNotifier(processErrorMessage(error, getState().ui.parameterize))), error.code === 'LOG-023' && dispatch(pushRoute('/bloqueo'))];
            };
            return Promise.all([hideProgress()].concat(_toConsumableArray(error instanceof ApiServerError ? getServerErrorDispatches() : [])));
          }
        case CREAR_TEMPORAL_SUCCESS:
          {
            var _payload$payload, _payload$payload2;
            var correo = payload === null || payload === void 0 ? void 0 : (_payload$payload = payload.payload) === null || _payload$payload === void 0 ? void 0 : _payload$payload.email;
            var telefono = payload === null || payload === void 0 ? void 0 : (_payload$payload2 = payload.payload) === null || _payload$payload2 === void 0 ? void 0 : _payload$payload2.phone;
            registerEvent(createSuccessfulFormEvent({
              category: eventTypes.categories.contrasegna,
              name: 'Enviar Contraseña Temporal: éxito'
            }));
            return Promise.all([hideProgress(), dispatch(hideNotifier()), dispatch(setCorreoTemporal(correo)), dispatch(setTelefonoTemporal(telefono)), action]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var crearNuevaFlujo = function crearNuevaFlujo(_ref3) {
  var dispatch = _ref3.dispatch,
    getState = _ref3.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var solicitud = action.payload;
      if (action.type === CREAR_NUEVA) {
        return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(securityEndpoints.usuario.cambiarContrasena(), function () {
          return crearNuevaSuccess(solicitud.contrasena);
        }, crearNuevaFail, {
          body: {
            contraseniaActual: solicitud.contrasenaActual,
            username: eliminarCaracteresEspeciales(getState().auth.rut),
            contrasenia: solicitud.contrasena
          },
          auth: true
        }))]);
      }
      return nextResponse;
    };
  };
};
export var crearNuevaProcesar = function crearNuevaProcesar(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case CREAR_NUEVA_FAIL:
          {
            return Promise.all([dispatch(hideSecondaryProgressBar()),
            // TODO mover códigos a módulo común
            payload.code === 'LOG-023' && dispatch(pushRoute('/bloqueo'))]);
          }
        case CREAR_NUEVA_SUCCESS:
          return Promise.all([dispatch(hideSecondaryProgressBar()), dispatch(hideNotifier()), dispatch(setDetalle({
            tieneContrasenaTemporal: false
          }))]);
        default:
          return nextResponse;
      }
    };
  };
};
export default [crearTemporalFlujo, crearTemporalProcesar, crearNuevaFlujo, crearNuevaProcesar];